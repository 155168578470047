import axios from '@/plugins/axios'

/**
 *  link with spreadsheet
 */
const linkWithSpreadsheet = async (id) => {
  try {
    const params = {
      _method: 'PUT'
    }
    const response = await axios.post(`companies/${id}/link-with-spreadsheet`, params)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update company
 */
const updateCompany = async (id, {
  name = null,
  // eslint-disable-next-line camelcase
  fantasy_name = null,
  // eslint-disable-next-line camelcase
  company_document = null,
  address = null,
  logo = null,
  country = null
}) => {
  try {
    const form = new FormData()
    form.append('country', country)
    form.append('name', name)
    form.append('fantasy_name', fantasy_name)
    form.append('company_document', company_document)
    form.append('address[zipcode]', address.zipcode)
    form.append('address[street]', address.street)
    form.append('address[number]', address.number)
    form.append('address[complement]', address.complement)
    form.append('address[neighborhood]', address.neighborhood)
    form.append('address[city]', address.city)
    form.append('address[state]', address.state)
    if (logo) form.append('logo', logo)
    form.append('_method', 'PUT')
    const response = await axios.post(`companies/${id}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update recipient
 */
const updateRecipient = async (id, { cnpj, name }) => {
  try {
    const params = {
      cnpj: cnpj,
      name: name,
      _method: 'PUT'
    }
    const response = await axios.post(`companies/${id}/recipient`, params)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  linkWithSpreadsheet,
  updateCompany,
  updateRecipient
}
