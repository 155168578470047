import axios from '@/plugins/axios'

const requestReport = async ({
  advertisers,
  campaigns,
  // eslint-disable-next-line camelcase
  start_date,
  // eslint-disable-next-line camelcase
  end_date,
  type,
  grouping,
  // eslint-disable-next-line camelcase
  email_to_send
}) => {
  try {
    const form = {
      advertisers,
      campaigns,
      start_date,
      end_date,
      type,
      grouping,
      email_to_send
    }
    const response = await axios.post('reports/request', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  requestReport
}
